import React from 'react'
import { Link as GLink } from 'gatsby'
import { Text, Link, Card, Box } from 'theme-ui'
import NewsletterForm from '@components/NewsletterForm'
import Section from '@components/Section'
import useMailChimp from '@helpers/useMailChimp'

const styles = {
  card: {
    position: `relative`
  },
  wrapper: {
    maxWidth: 500,
    textAlign: `center`,
    mx: `auto`,
    py: 3
  },
  icons: {
    display: [`none`, null, `block`],
    position: `absolute`,
    top: `-3rem`,
    left: `5rem`,
    svg: {
      display: `block`
    }
  },
  plane: {
    fontSize: `9rem`,
    color: `beta`
  },
  wind: {
    fontSize: `7rem`,
    color: `omegaLight`,
    transform: `rotate(120deg)`,
    mt: `0.5rem`,
    ml: `-3rem`
  },
  form: {
    maxWidth: 300,
    mx: `auto`,
    mt: 4
  }
}

const NewsletterCompact = props => {
  const {
    handleSubmit,
    canSubmit,
    submitting,
    message,
    success
  } = useMailChimp()

  return (
    <Card variant='paper' sx={styles.card}>
      <Box sx={styles.wrapper}>
        <Text variant='h4'>
          Du möchtest deine Therapie noch besser gestalten?
        </Text>
        <Text variant='small' style={{ marginBottom: '12px' }}>
          Dann abonniere meinen Newsletter und erhalte neue Artikel direkt per Email!
        </Text>
        <NewsletterForm
          {...{ handleSubmit, canSubmit, submitting, message, success }}
        />
        <Text variant='xsmall' style={{ marginTop: '12px' }}>
          Abmeldung jederzeit möglich. Normalerweise 1 bis 2 Emails im Monat, maximal alle 2 Wochen.{' '}
          {/* Du erhältst neue Artikel wenn ich sie veröffentliche, und evtl. Hinweise zu meinen Angeboten, sollte ich z.B. irgendwann mal ein Buch veröffentlichen. */}
          Einwilligung zum Newsletter-Versand gemäß meiner{' '}
          <Link as={GLink} to="/datenschutz">
            Datenschutzerklärung
          </Link>
          .
        </Text>
      </Box>
    </Card>
  )
}

export default NewsletterCompact

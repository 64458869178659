import React from 'react'
import PropTypes from 'prop-types'
import { GatsbyImage as Img } from 'gatsby-plugin-image'
import { css, Text } from 'theme-ui'
import Divider from '@components/Divider'
import getImageVariant from '@components/utils/getImageVariant'
import sanitizeHtml from 'sanitize-html'

const styles = {
  regular: {
    minHeight: `23rem`,
    img: {
      borderRadius: `lg`
    }
  },
  wide: {
    img: {
      borderRadius: `lg`
    }
  },
  inCard: {
    mt: -4,
    mx: -4,
    img: {
      borderRadius: t => `${t.radii.lg} ${t.radii.lg} 0 0`
    }
  },
  inCardLarge: {
    mt: [-4, -5],
    mx: [-4, -5],
    img: {
      borderRadius: t => `${t.radii.lg} ${t.radii.lg} 0 0`
    }
  }
}

export const PostImage = ({
  title,
  thumbnail,
  wide,
  full,
  inCard,
  thumbnailText,
  inCardLarge
}) => {
  if (!thumbnail) return null

  const variant = (wide && 'wide') || (full && 'full') || 'regular'
  const image = getImageVariant(thumbnail, 'hero')
  const sanitized = thumbnailText ? sanitizeHtml(thumbnailText, {
    transformTags: {
      a: sanitizeHtml.simpleTransform('a', {
        target: '_blank',
        rel: 'noopener noreferrer'
      })
    },
    allowedAttributes: {
      a: [ 'href', /*'name',*/ 'target', 'rel' ]
    }
  }) : ''

  return (
    <>
      <figure>
        <Img
          image={image}
          alt={title}
          css={css({
            ...styles[variant],
            ...(inCard && styles.inCard),
            ...(inCardLarge && styles.inCardLarge)
          })}
          imgStyle={
            variant === 'wide' || variant === 'full' ? styles.full : undefined
          }
        />
        {sanitized && (
          <figcaption
            css={css(t => ({ ...t.styles.figcaption, a: { ...t.styles.a } }))}
            dangerouslySetInnerHTML={{ __html: sanitized }}
          ></figcaption>
        )}
      </figure>
      <Divider space={3} />
    </>
  )
}

PostImage.propTypes = {
  wide: PropTypes.bool,
  full: PropTypes.bool,
  inCard: PropTypes.bool,
  inCardLarge: PropTypes.bool
}
